<!-- 仓库管理-->
<template>
  <div>
        <ctrl-box class="ctrl-box" ::data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doAdd()" v-if="hasPermission('store:add')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEdit()" v-if="hasPermission('store:edit')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" type="danger" @click="doDelete()" v-if="hasPermission('store:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('仓库名')">
                        <el-input v-model="scope.query.name" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('日期')">
                        <el-date-picker  v-model="scope.query.Date" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="stores"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

        
        <el-dialog :title="dgTitle" :close-on-click-modal="false" 
            :visible.sync="dgVisible" width="600px"
            :fullscreen="isMinScreen">
                <edit :store="curr" @close-dg="closeEditDialog" />
        </el-dialog>
    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Edit from "./StoreEdit.vue";

export default {
    name: 'store',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Edit: Edit,
    },
    mounted:function(){
        this.list();
    },
    data(){
        return {
            query:{
                length: 10,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
            },
            showQuery: false,
            columns: [
                {type: 'checkbox', minWidth: 60},
                {field: 'id', title: this.msg('ID'), minWidth: 80, sortable: true},
                {field: 'name', title: this.msg('仓库名'), minWidth: 130, sortable: true},
                {field: 'location', title: this.msg('仓库位置'), minWidth: 150, sortable: true},
                {field: 'createrName', title: this.msg('创建者'), minWidth: 120, sortable: true},
                {field: 'created', title: this.msg('创建时间'), minWidth:130, sortable: true}
            ],
            stores: [],
            dgTitle: '',
            dgVisible: false,
            curr: null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods: {
        list(){
            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.store.list, param)
                .then((result) => {
                    if(result.status){
                        this.stores = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        doAdd(){
            this.curr = { 
                name: null,
                location: null,
            };

            this.dgTitle = this.msg("新增仓库");
            this.dgVisible = true;
        },
        doEdit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }

            this.curr  = selectes[0];

            this.dgTitle = this.msg("编辑仓库")+":"+this.curr.name;
            this.dgVisible = true;
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.fmt('确定要删除仓库《%s》吗?', selectes.map(e => e.name).join(",")), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.store.delete+"?ids="+selectes.map(e => e.id).join(","))
                    .then((result) => {
                        _this.$message(result.msg || _this.msg('删除成功'));
                        _this.list(true);
                    });
            }).catch((err) => {
                // console.log(err);
            });
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
