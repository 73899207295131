<!--编辑仓库-->
<template>
    <el-form ref="form" :model="store" label-width="80" size="mini" :inline="true">
        <h3>{{msg('仓库信息')}}</h3>
        <el-row>
            <el-col :xs="24" :sm="12">
                <el-form-item :label="msg('仓库名')">
                    <el-input v-model="store.name" autocomplete="off" ></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
                <el-form-item :label="msg('位置')">
                    <el-input v-model="store.location" autocomplete="off" ></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col v-if="store.created" :xs="24" :sm="12">
                <el-form-item :label="msg('创建人')">
                    <span>{{store.createrName}}</span>
                </el-form-item>
            </el-col>
            <el-col v-if="store.created" :xs="24" :sm="12">
                <el-form-item :label="msg('创建时间')">
                    <span>{{store.created}}</span>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'storeEdit',
    data(){
        return {
        }
    },
    props:{
        store: Object
    },
    methods:{
        onSubmit(){
            if(!this.store.name){
                this.$message(this.msg("仓库名不能为空"));
                return;
            }

            var url = null;
            if(this.store.id==null){
                url = this.$kit.api.store.create;
            }else{
                url = this.$kit.api.store.update;
            }

            var _this = this;
            this.$axios.post(url, this.store)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')));
                        if(result.status){
                            _this.$emit("close-dg", true);
                        }
                    }else{
                        _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                    }
                });
        },
    }
}
</script>
<style lang="scss" scoped>

</style>